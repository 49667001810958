/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(document).on('click', '[data-toggle="lightbox"]', function(event) {
          event.preventDefault();
          $(this).ekkoLightbox();
        });

        $(document).ready(function() {
          // Custom
          var stickyToggle = function(sticky, stickyWrapper, scrollElement) {
            var stickyHeight = sticky.outerHeight();
            var stickyTop = stickyWrapper.offset().top;
            if (scrollElement.scrollTop() >= stickyTop){
              stickyWrapper.height(stickyHeight);
              sticky.addClass("is-sticky");
              sticky.width($('.container').width());
              $('#yoruba-logo-small').show();
            }
            else{
              sticky.removeClass("is-sticky");
              stickyWrapper.height('auto');
              $('#yoruba-logo-small').hide();
            }
          };

          // Find all data-toggle="sticky-onscroll" elements
          $('[data-toggle="sticky-onscroll"]').each(function() {
            var sticky = $(this);
            var stickyWrapper = $('<div>').addClass('sticky-wrapper'); // insert hidden element to maintain actual top offset on page
            sticky.before(stickyWrapper);
            sticky.addClass('sticky');

            // Scroll & resize events
            $(window).on('scroll.sticky-onscroll resize.sticky-onscroll', function() {
              stickyToggle(sticky, stickyWrapper, $(this));
            });

            // On page load
            stickyToggle(sticky, stickyWrapper, $(window));
          });

//          $('#socials div').socialLikes();
            $('.carousel').carousel();
            $('.carousel').on('hover', function() {
                $(this).find('.carousel-caption').slideToggle();
                $(this).find('.carousel-indicators').slideToggle();
            });
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'single': {
      init: function() {
        // JavaScript to be fired on the evento single
//        var lat = $('#evento-mappa-latitudine').val();
//        var long = $('#evento-mappa-longitudine').val();
//        var mymap = L.map('evento-mappa').setView([lat, long], 15);
//
//        L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiZ3B6b2JvbGkiLCJhIjoiY2ozcjNkdXNqMDA1ZDMybzVmeWV5Nm5ocyJ9.zOA7BwlQTbgMKM7SyRM9lw', {
//            maxZoom: 18,
//            attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, ' +
//                '<a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
//                'Imagery © <a href="http://mapbox.com">Mapbox</a>',
//            id: 'mapbox.streets'
//        }).addTo(mymap);
//        var marker = L.marker([lat, long]).addTo(mymap);
//
        $(document).ready(function(){
            $.get('https://nominatim.openstreetmap.org/search/'+$('#evento-mappa-posizione').val()+'?format=json&limit=1',
                function (geodata) {
                    if (geodata) {
                        var lat = geodata[0].lat;
                        var lon = geodata[0].lon;

                        var mymap = L.map('evento-mappa').setView([lat, lon], 15);

                        L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiZ3B6b2JvbGkiLCJhIjoiY2o1YXBzNDVtMXloaTJ3b2FtM3F6bDFwNyJ9.4ITArT0IIvSrKBipcjKnJw', {
                            maxZoom: 18,
                            attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, ' +
                                '<a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                                'Imagery © <a href="http://mapbox.com">Mapbox</a>',
                            id: 'mapbox.streets'
                        }).addTo(mymap);
                        var marker = L.marker([lat, lon]).addTo(mymap);
                    }
                }
            );
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
